.cardFrameProgress {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  text-align: center;
  border-radius: 6px;
  overflow: hidden;
  padding: 5vh 4vw;
  background: rgb(255, 255, 255);
  min-width: 200px;
  min-height: 220px;
}

.guide-video-wrap {
  width: 100%;
  display: 'flex';
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

/* Media query for screens up to 600px */
@media screen and (max-width: 600px) {
  .cardFrameProgress {
    height: 70%;
    margin-top: 15%;
  }
}

/* Media query for screens between 601px and 1024px */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .cardFrameProgress {
    height: 80%;
    margin-top: 10%;
  }
}

/* Media query for screens larger than 1024px */
@media screen and (min-width: 1025px) {
  .cardFrameProgress {
    height: 100%;
  }
}