.gpt-data-select-modal {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 55%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;

    &:focus-visible {
        outline: none;
    }

    .modal-body {
        .title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .using-gpt-text {
                font-size: 16px;
                font-weight: bold;
                color: rgba(0, 41, 255, 1);
                ;
            }

            .toggler-container {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 10px;
            }
        }

        .data-adding-container {
            padding: 10px 4vw 10px 4vw;

            .place-holder-text {
                margin-left: 15px;
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: thin
            }

            .gpt-inputs-container {
                .gpt-input-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 20px;
                }
            }

            .gpt-input-box {
                display: flex;
                flex-direction: row;
                align-items: center;

                .ipb-number {
                    margin-left: 10px;
                    margin-right: 5px;
                    font-size: 18px;
                    font-weight: 600;
                    width: 20px;
                }
            }

            .add-more-field-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                cursor: pointer;
                color: rgba(0, 41, 255, 1);
                font-size: 16px;
                font-weight: bold;
            }
        }

        .btns-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 30px;
        }

        .start-btn {
            /* center this button */
            text-transform: none;
            width: 200px;
            border-radius: 5px;
            border: 2px solid rgba(0, 41, 255, 1);
            background-color: rgba(0, 41, 255, 1);
            color: white;
            font-weight: 700;
        }

        .skip-btn {
            float: right;
            margin-left: 50px;
            text-transform: none;
            width: 200px;
            border: 2px solid rgba(0, 41, 255, 1);
            border-radius: 5px;
            color: rgba(0, 41, 255, 1);
            font-weight: 700;
        }

    }
}

.form-control::placeholder {
    font-style: italic;
}