.preview-modal {
    height: 80%;
    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    padding: 5px;

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .section-title {
            display: flex;
            flex-direction: row;
            align-items: center;

            .blue-btn {
                width: fit-content;
                margin-left: 1rem;
            }

            .solid-blu {
                background-color: #0029FF !important;
                color: #fff !important;
                border: 1px solid #0029FF !important;
            }
        }

        .modal-title {
            font-size: 1.2rem;
            font-weight: 400;
        }

        .close-icon {
            cursor: pointer;
            font-weight: 600;
        }
    }

    .modal-body {
        height: 100%;
        overflow: hidden;

        .loading-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 400px
        }

        .table-holder {
            height: 100%;
            width: 100%;
            border: 1px solid #eae5e5;
            border-radius: 4px;
        }

        .table-o-container {
            width: 100%;
            height: 100%;
            overflow: auto;
        }

        .clickable-navigation-link {
            cursor: pointer;
            color: #0029FF;
            text-align: center;
            font-weight: 600;

            &:hover {
                color: #0029FF;
            }

            .icon {
                font-size: 14px;
            }
        }
    }
}