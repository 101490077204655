.cs-select-modal table thead th{
    padding: 10px 16px !important;
    font-weight: 600;
}
.cs-select-modal table td{
    padding: 5px 16px !important;
    cursor: pointer;
}

.cs-select-modal .blue-btn{
    width: auto;
    min-width: 180px;
    font-size: 20px;
}

.cs-config-buttons{
    text-align: right; 
    width: 100%;
    display: block !important;
}
.cs-select-modal table .MuiButton-root{
    text-align: right;
    width: 40px !important;
    display: block;
    float: right;
    min-width: 0;
}

.cs-config-buttons button{
    height: 50px !important;
    padding: 0 20px !important;
 }
 .cs-config-buttons button.with-border{
    margin-left: 160px;
 }


 .save-st-button{
    float: left;
 }

 .MuiDialog-container .MuiPaper-root{
    padding: 10px 30px;
 }

.st-button-panel{
    text-align: left !important;
    display: block !important;
}
.st-button-panel  button{
    margin-left: 215px;
}

.structure-popup .c-label{
    line-height: 40px;
}


 .MuiDialogTitle-root{
    padding: 0 !important;
    margin-bottom: 10px !important;
    text-align: center;
    font-size: 18px !important;
 }
 .MuiDialog-container button{
    height: 38px; 
 }

 .MuiDialog-container * {
    font-family: "Poppins", sans-serif !important;
 }

 .file-1 img, .file-2 img, .file-3 img {
    color: #161616;
    opacity: 0.8;
}