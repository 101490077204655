.badge {
    width: fit-content;
    height: fit-content;
    background-color: #0029ff;
    color: white;
    font-size: 14px;
    padding: 8px 8px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.capitalize {
    text-transform: capitalize;
}

.my-plan-btn {
    background: #0029ff !important;
    color: white !important;
}

.my-plan-btn:hover {
    background: #133aff !important;
}

.pl-80 {
    padding-left: 80px;
}

.pl-5 {
    padding-left: 5px !important;
}

.pt-0{
    padding-top: 0px;
}

.pt-24 {
    padding-top: 24px;
}

.pt-15 {
    padding-top: 15px !important;
}

.justify-center {
    justify-content: center;
}

.fs-8 {
    font-size: 8px;
}

.flex-col{
    flex-direction: column;
}