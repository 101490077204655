.main-container {
  height: 90vh;
  display: flex;
  align-content: center;
  background-color: #e4e4ff;
  border-radius: 10px;
  color: black;
  text-align: center;
  min-width: 150px;
  min-height: 600px;
  /* box-shadow: rgba(31, 30, 30, 0.205) 0px 5px 15px; */
}

/* .root {
    border: 0,
    borderRadius: 3,
    maxHeight: 400,
    overflow: "auto",
  }
  .cell {
    fontSize: "11px",
    padding: "10px 5px",
  }
  .headingCell {
    color: "#0093ff",
    fontSize: "11px",
    padding: "10px 5px",
    fontWeight: "700",
  },
   */
.headingCell {
  color: "#0093ff";
  font-size: "11px";
  padding: "10px 5px";
  font-weight: "700";
}

.cell {
  font-size: "11px";
  padding: "10px 5px";
}

.root-view {
  border: 0;
  border-radius: 3;
  max-height: 400;
  overflow: "auto";
}

.pagebutton {
  background-color: black;
}

div.sc-bdvvtL.jLpTPG{
  padding-top: 0px;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1ygcj2i-MuiTableCell-root{
  padding-top: 0px;
}

div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-md-4.css-1g3enin-MuiGrid-root{
  padding-top: 0px;
}

.cell-header {
  position: relative;
  font-size: 14px;
}

.info-icon {
  position: relative;
  top: -5px;
  right: 0px;
}

.top-section{
  padding-left: 15px !important;
}