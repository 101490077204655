.gpt-retry-modal {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 500px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;

    &:focus-visible {
        outline: none;
    }

    .modal-body {
        padding-top: 0px;

        .title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 20px;
            }
        }

        .description {
            padding: 0 20px;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 20px;
            text-align: left;
        }

        .btns-container {
            padding: 0 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 30px;
        }

        .start-btn {
            /* center this button */
            text-transform: none;
            width: 180px;
            height: 35px;
            ;
            border-radius: 5px;
            border: 2px solid rgba(0, 41, 255, 1);
            background-color: rgba(0, 41, 255, 1);
            color: white;
            font-weight: 700;
            padding-bottom: 8px;
        }

        .skip-btn {
            float: right;
            margin-left: 50px;
            text-transform: none;
            width: 180px;
            height: 35px;
            border: 2px solid rgba(0, 41, 255, 1);
            border-radius: 5px;
            color: rgba(0, 41, 255, 1);
            font-weight: 700;
            padding-bottom: 8px;


        }

        .skip-btn.Mui-disabled {
            background-color: #636363ab;
            color: #ffffff;
            border: 2px solid #f5f5f5;
            cursor: not-allowed;
            pointer-events: all;
        }

        .start-btn.Mui-disabled {
            background-color: rgb(32, 50, 141);
            color: #ffffff;
            border: 2px solid #f5f5f5;
            cursor: not-allowed;
            pointer-events: all;
        }

    }
}