.payment-status-modal {
    height: 45%;
    display: flex;
    flex-direction: column;
    width: 25%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;

    @media (max-width: 768px) {
        width: 90%;
    
    }

    &:focus-visible {
        outline: none;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .pricing-heading {
            font-size: 40px;
            font-weight: 700;
            color: rgba(0, 41, 255, 1);
    
            @media (max-width: 768px) {
                font-size: 30px;
            }
        }

        .message-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .message-text {
                font-size: 18px;
                font-weight: 400;
                color: #1A1A1A;
                margin-top: 20px;
            }
        }

        .modal-footer-section {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
    
            .footer-text {
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                color: #7b7b7b;
            }
        }
    }

    

}