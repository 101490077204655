#root {
  height: 100vh;
  /* min-height: 650px; */
}

.app {
  height: 100%;
  font-family: "Poppins", sans-serif !important;
}

/* font */
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}

/* common */
.blue-btn {
  width: 220px;
  background-color: #0029ff !important;
  border-radius: 5px !important;
  /* box-shadow: 1px 5px 15px 5px #002aff42 !important; */
  text-transform: none !important;
  padding: 8px 16px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.blue-btn:hover,
.blue-btn:focus {
  background-color: #0029FF !important;
}

.blue-btn:disabled {
  color: #dddddd !important;
  background-color: #0328df83 !important;
}

.blue-btn-2 {
  width: 220px;
  background-color: #0029ff !important;
  border-radius: 5px !important;
  /* box-shadow: 1px 5px 15px 5px #002aff42 !important; */
  text-transform: none !important;
  padding: 0px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.blue-btn-2:hover,
.blue-btn-2:focus {
  background-color: #0029FF !important;
}

.blue-btn-2:disabled {
  color: #dddddd !important;
  background-color: #0328df83 !important;
}

.blue-btn-3 {
  width: 120px;
  background-color: #0029ff !important;
  border-radius: 5px !important;
  /* box-shadow: 1px 5px 15px 5px #002aff42 !important; */
  text-transform: none !important;
  padding: 6px 16px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.blue-btn-3:hover,
.blue-btn-3:focus {
  background-color: #0029FF !important;
}

.blue-btn-3:disabled {
  color: #dddddd !important;
  background-color: #0328df83 !important;
}


.blue-btn-4 {
  width: 120px;
  background-color: #ffffff !important;
  border-radius: 5px !important;
  border: 2px solid #0029ff !important;
  text-transform: none !important;
  padding: 6px 16px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  color: #0029FF !important;
}

.blue-btn-4:hover,
.blue-btn-4:focus {
  background-color: #0029FF !important;
  color: #ffffff !important;
}

.blue-btn-4:disabled {
  color: #475fd7 !important;
  border: 2px solid #475fd7 !important;
  background-color: rgba(207, 207, 207, 0.858) !important;
}

.btn-align-center {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue-btn-small {
  width: 160px;
  background-color: #0029ff !important;
  border-radius: 5px !important;
  /* box-shadow: 1px 5px 15px 5px #002aff42 !important; */
  text-transform: none !important;
  padding: 5px 8px !important;
  line-height: 12px !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}

.with-border {
  background-color: #ffffff !important;
  border: 2px solid #0029ff !important;
  color: #0029ff !important;
}

.with-border:hover,
.with-border:focus {
  background-color: #f1f1f1 !important;
  border: 2px solid #0029ff !important;
  color: #0029ff !important;
}

.loading-btn-circle {
  width: 24px !important;
  height: 24px !important;
  color: #ffffff !important;
}

.error-msg {
  color: #ff0000;
  font-size: 14px;
  padding-left: 5px;
}

.error-msg-text-selection {
  color: #ff0000;
  font-size: 10px;
}

.error-msg-table-selection {
  color: #ff0000;
  font-size: 10px;
  margin-left: 13%;
}

.left-side {
  width: 100px;
  flex: 0 0 auto;
}

.right-side {
  width: calc(100% - 100px);
  flex: 0 0 auto;
  overflow-y: auto;
}

.check_ico {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
}

.check_ico_checked {
  font-size: 15px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #c1c8cf;
  align-items: center;
  justify-content: center;
  display: flex;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.082);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.082);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0px 4px 104px 17px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0px 4px 104px 17px rgba(0, 0, 0, 0.5);
  background-color: #1d195315;
}

:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba (0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 6px rgba (0, 0, 0, 0.2);
  border-radius: 10px;
}

:hover::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0px 4px 104px 17px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: inset 0px 4px 104px 17px rgba(0, 0, 0, 0.6);
  background-color: #1d195315;
}

.bdr-leftt {
  border-left: 1px solid #dddddd;
}

:focus-visible {
  outline: #002aff auto 1px;
}

::selection {
  color: #002aff;
  background: #002aff1c;
}

/* landing */
.lading-container {
  text-align: center;
}

.landing-logo {
  width: 100px;
}

/* signup & login */
.logo-name {
  font-weight: 800;
  color: #0029ff;
}

.logo-name a {
  font-weight: 800;
  color: #0029ff;
  text-decoration: none;
}

.signup-container,
.login-container {
  width: 100%;
}

.first-row h3 {
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;
}

.first-row p {
  color: #707070;
  margin-bottom: 0px;
  margin-top: 8px;
  text-align: center;
  font-size: 15px;
}

.first-row span {
  color: #0029ff;
  cursor: pointer;
}

.second-row label {
  color: #707070;
  padding-left: 5px;
  font-size: 15px;
}

.second-row input,
.second-row select {
  padding: 12px;
}

.second-row .btn-signup,
.second-row .btn-login {
  /* width: 100%; */
  text-align: center;
}

.second-row .policy {
  /* text-align: center; */
  color: #707070;
  font-size: 14px;
}

.second-row .policy p {
  height: 42px;
}

.second-row .policy span,
.forget-pass {
  color: #0029ff;
  cursor: pointer;
}

.second-row .policy span a {
  color: #0029ff;
  text-decoration: none;
}

/* sidebar */
.side-bar {
  /* background-color: #fafafa; */
  height: 100%;
  overflow-y: auto;
  /* border-right: 1px solid #dddddd; */
}

.avatar-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #0029ff;
}

.avatar-circle .name {
  max-width: 60px;
  font-weight: 500;
  font-size: 13px;
}

.avatar-circle .count {
  font-size: 12px;
}

.side-bar .MuiListItemButton-root {
  box-sizing: border-box !important;
  border-left: 5px solid #ffffff00 !important;
  color: #707070 !important;
  padding: 0px !important;
  margin: 8px 0px !important;
  flex-direction: column !important;
}

.side-bar .MuiListItemButton-root.Mui-selected {
  background-color: transparent !important;
  border-left: 5px solid #0029ff !important;
  color: #000000 !important;
}

.side-bar .css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected:hover {
  background-color: transparent;
}

.side-bar .css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected .css-cveggr-MuiListItemIcon-root {
  color: #000000;
}

.side-bar .css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: transparent;
}

.side-bar .css-cveggr-MuiListItemIcon-root {
  min-width: unset;
}

.side-bar .MuiListItemButton-root.Mui-selected:hover {
  background-color: transparent !important;
}

.side-bar .MuiTypography-root {
  text-align: center;
}

.side-bar .MuiListItemButton-root.Mui-selected .MuiListItemIcon-root {
  color: #000000 !important;
}

.side-bar .MuiListItemButton-root:hover {
  background-color: transparent !important;
}

.side-bar .MuiListItemIcon-root {
  min-width: unset !important;
  margin-top: 3px;
}

.side-bar .MuiTypography-root {
  font-size: 12px !important;
}

@media (min-height: 600px) {
  .margin-cls {
    margin-top: 8vh;
    margin-bottom: 5vh;
  }
}

/* tabs */
.tab-area .MuiButtonBase-root.MuiTab-root {
  text-transform: none !important;
  color: #707070 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  min-height: 50px !important;
  /* padding: 9px 30px !important; */
}

.tab-area .MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #000000 !important;
  background-color: transparent !important;
}

.tab-area .MuiTabs-indicator {
  height: 4px;
  background-color: #002aff !important;
}

.tab-area .MuiSvgIcon-root {
  color: #0000008a !important;
}

.tab-area .Mui-selected .MuiSvgIcon-root {
  color: #000000 !important;
}

/* account */
.account-area label {
  color: #707070;
  padding-left: 5px;
  font-size: 15px;
}

.account-area input,
.account-area select {
  padding: 12px;
}

@media (max-width: 992px) {
  .remove-col {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .extra-pading {
    padding-left: 80px !important;
  }
}

@media (min-width: 992px) {
  .extra-pading-2 {
    padding-left: 50px !important;
  }
}

.border-div {
  width: 50%;
  height: 100%;
  border-right: 1px solid #dddddd;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
}

.form-control:disabled {
  color: #707070;
}

/* mail */
.mail-area .MuiFormControlLabel-root .MuiFormControlLabel-label {
  margin-left: 15px !important;
}

/* password */
.password-area label {
  color: #707070;
  padding-left: 5px;
  font-size: 15px;
}

.password-area input {
  padding: 12px;
}

.password-area .form-control {
  padding-right: 50px;
}

.toggle-password {
  cursor: pointer;
  float: right;
  margin-right: 20px;
  margin-top: -38px;
}

/* about you */
.about-you-container h3 {
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;
}

.about-you-container label {
  color: #707070;
  padding-left: 5px;
  font-size: 15px;
}

.about-you-container input,
.about-you-container select {
  padding: 12px;
}

/* element selector */
.element-selector .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.element-selector .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  margin-top: 5px !important;
  top: 0px !important;
  height: 36px !important;
}

.disabled-bdr:disabled {
  border: 1px solid #e0e0e0 !important;
}

.disabled-clr {
  color: #e3e3e3 !important;
}

/* predict btn */
.first-btn {
  margin: 5px 0 !important;
  padding: 5px 10px !important;
  color: #484464 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid rgb(201, 201, 201) !important;
}

/* predict btn */
.first-btn-blue {
  margin: 5px 0 !important;
  padding: 5px 10px !important;
  background-color: #0029ff !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid rgb(201, 201, 201) !important;
}

.middle-btn {
  margin: 5px 0 !important;
  padding: 5px 10px !important;
  color: #484464 !important;
  border-radius: 0px !important;
  border: 1px solid rgb(201, 201, 201) !important;
}

.last-btn {
  margin: 5px 0 !important;
  padding: 5px 10px !important;
  color: #484464 !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid rgb(201, 201, 201) !important;
}

.page-count {
  margin: 5px 0;
  border-radius: 20px;
  background-color: white !important;
  color: blue !important;
  float: right;
  border: none !important;
  cursor: default;
  font-weight: 600;
}

/* predict sidebar */
.predict-sidebar .scroll-area {
  padding: 5px;
  height: 100%;
}

.predict-sidebar .scroller {
  height: calc(100% - 32px);
  overflow: auto;
}

.bottom-sidebar-btn-area {
  height: 55px !important;
  border-top: 1px solid rgb(201, 201, 201);
}

.predict-sidebar .table-header {
  color: #427861;
}

.predict-sidebar .key-val-header {
  color: #ff0000;
}

.predict-sidebar #tabelView {
  width: calc(100% - 10px);
  min-width: 100px;
  padding-top: 0.125rem;
  max-height: 32px;
  padding-bottom: 0.125rem;
}

.predict-sidebar #keyValuePair {
  width: calc(100% - 35px) !important;
  min-width: 100px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.predict-sidebar .MuiPaginationItem-root {
  line-height: 1 !important;
}

.predict-sidebar .MuiTable-root {
  font-family: "Poppins", sans-serif !important;
}

/* loader */
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.644);
  z-index: 2;
  cursor: pointer;
}

#overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(141, 136, 136, 0.617);
}

#text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: #6f6f6f;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.center-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.seven.dots {
  position: relative;
  width: 80px;
  height: 80px;
  animation: rotateMain 1s ease infinite forwards;
}

.seven .dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: #0029ff;
  animation: none;
  transform: translate(0px);
  animation: changePos 1s linear infinite forwards;
}

.seven .dot:nth-of-type(1) {
  left: 0%;
  top: 0;
}

.seven .dot:nth-of-type(2) {
  left: 0;
  top: 100%;
}

.seven .dot:nth-of-type(3) {
  left: 100%;
  top: 0;
}

.seven .dot:nth-of-type(4) {
  left: 100%;
  top: 100%;
}

@keyframes rotateMain {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes changePos {
  50% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
  }
}

/* resiziable columns */
.SplitPane.horizontal {
  width: unset !important;
  right: 0 !important;
  left: 0 !important;
}

.Pane.horizontal.Pane2 .Pane {
  height: 100%;
}

.Resizer.horizontal {
  height: 3px;
  margin: 0px;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  cursor: row-resize;
  width: 100%;
  z-index: 0;
}

.Resizer.horizontal:hover {
  border-top: 1px solid #0029ff;
  border-bottom: 1px solid #0029ff;
  background-color: #0029ff;
}

/* upload */
.upload-container {
  height: 100%;
  margin: 20px 0px;
}

.upload-card {
  border: 2px solid #c4c4c4;
  border-radius: 7px;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.14); */
  padding: 15px 50px;
  height: 32vh;
  min-height: 10vh;
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.back-img {
  background-image: url(../src/assets/images/upload.svg);
}

.back-img-2 {
  background-image: url(../src/assets/images/gear.svg);
}

.upload-card-2 {
  border: 2px solid #c4c4c4;
  border-radius: 7px;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.14); */
  padding: 10px;
  text-align: center;
}

.upload-card small,
.upload-card-word small {
  font-size: 12px;
}

.lang-select {
  border: 2px solid #c4c4c4;
  border-radius: 7px;
  padding: 10px 20px 15px;
}

.pdf-select {
  border: 2px solid #c4c4c4;
  border-radius: 7px;
  padding: 10px 20px 10px;
}

.upload-close-icon {
  color: #ff0000;
  cursor: pointer;
}

.upload-close-icon:hover {
  background-color: #00000011;
}

/* loading */
.MuiLinearProgress-root {
  height: 30px !important;
  border-radius: 20px !important;
  background-color: #ebebeb !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #0029ff !important;
}

.file-1 img,
.file-2 img,
.file-3 img {
  height: 3vh;
  min-height: 16px;
  opacity: 0.3;
}

.selected.file-1 img,
.selected.file-2 img,
.selected.file-3 img {
  opacity: 1;
}

.file-1 small,
.file-2 small,
.file-3 small {
  font-size: 12px;
}

.file-1,
.file-2,
.file-3 {
  width: 14vw;
  max-width: 200px;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid black;
  color: black;
  position: relative;
  box-sizing: border-box;
}

.file-1:hover,
.file-2:hover,
.file-3:hover {
  background-color: #0000000c;
}

.selected,
.selected:hover {
  border: 3px solid #0029ff;
  /* background-color: #002aff1a; */
  color: #000000;
}

.ratigg-tbl tr td {
  padding: 0px 40px;
}

.label-add-float {
  position: absolute;
  right: 21px;
  top: 30px;
  width: 160px;
  background-color: #ffffff;
  padding: 4px 5px;
  box-sizing: border-box;
  border-left: 1px solid #00000027;
  border-bottom: 1px solid #00000027;
  border-right: 1px solid #00000027;
  box-shadow: 0px 4px 62px 11px rgba(0, 0, 0, 0.25);
}

.doc {
  color: #0029ff;
  font-weight: 800;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
}

.key-val-header .blue-btn-small .MuiSvgIcon-root {
  height: 16px !important;
  /* width: 16px; */
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}

/* Word Counter */
.word-counter .upload-card-word {
  min-height: 180px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2px solid #c4c4c4;
  border-radius: 7px;
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.disable-sign-up-text {
  color: #707070 !important;
  cursor: unset !important;
}

.lang-text-side {
  cursor: pointer;
}

.lang-text-side:hover {
  background-color: #002aff3a;
}

.page-count-new {
  width: 45px;
  border: 2px solid #c9c9c9;
  border-radius: 6px;
  text-align: center;
  color: #0029ff;
  font-weight: 600;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.account-page {
  padding-top: 2px !important;
}

.tables-lable {
  color: #ffea61;
}

.text-lable {
  color: #002aff;
}

.help-modal {
  position: absolute;
  top: 6%;
  right: 2% !important;
  transform: translate(0%, 0%) !important;
  width: 540px !important;
  height: 90% !important;
  margin-bottom: 0% !important;
  background-color: white !important;
  border: none !important;
  border-radius: 4px;
  padding: 20px !important;
  outline: none;
}

.help-modal:hover,
.help-modal:focus {
  background-color: white !important;
  border: none !important;
  outline: none !important;
}

.text-danger {
  color: #c73b3b !important;
}

.txt-key-input {
  width: 100%;
  border: '1px solid #ced4da';
  border-radius: 3px;
  color: 'grey';
  font-size: 13px;
  font-weight: 600;
  display: inline;
}

.txt-val-input {
  display: inline;
  width: 100%;
  border: '1px solid #ced4da';
  border-radius: 3px;
  font-size: 13px;
  word-break: break-word;
}

.key-style-k {
  color: grey;
  font-size: 13px;
  font-weight: 600;
  display: inline;
}

.val-style-v {
  display: inline;
  font-size: 13px;
}

.dropdown-select {
  border: '1px solid black'
}

.dropdown-un-select {
  border: '1px solid #F2F2F2'
}

.chk-grid {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.color-box {
  width: 13px;
  height: 30px;
  border-radius: 3px
}

.help-icon-end {
  padding-right: 20px;
  display: flex;
  justify-content: center;
}

.auto-size{
  overflow: auto;
  padding-bottom: 10px;
}

.split-resizer{
  padding: 1.5px;
}

/* Spinner for text and column selection */
.loading-square-container {
  position: relative;
  width: 43px;
  height:43px;
  margin-right: 5px;
  padding:auto;
}

.loading-square {
  position: relative;
  width: 20px;
  height:20px;
  padding:auto;
  margin-right: 6px;
}

.spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
