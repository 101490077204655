.horizontal-line {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #808080;
  transform: translateY(-1px);
  pointer-events: none;
  z-index: 10;
}

.vertical-line {
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #808080;
  transform: translateX(-1px);
  pointer-events: none;
  z-index: 10;
}

.canvas-section {
  position: relative;
}

.custom-mouse-pointer {
  display: none;
}

.canvas-section:hover .custom-mouse-pointer {
  display: block;
}

.hide-cursor {
  cursor: none;
}
