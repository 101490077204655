.pricing-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 384px;
  height: fit-content;
  border: 4px solid #f1f3fa;
  border-radius: 8px;

  .title-section {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 700;
    }

    .price-display {
      font-weight: 700;
    }

    .package-description {
      font-weight: 400;
      color: #667085;
    }

    .pricing-period {
      font-weight: 400;
    }
  }

  .body-section {
    .features {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    .white-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: white;
    }

    .disabled{
      background-color: #E9E9EA;
    }

    .transparent-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: transparent;
    }

    .feature {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }

    .feature-text {
      color: #1a1a1a;
      font-weight: 400;
      padding-left: 12px;
    }
  }

  .lower-section {
    display: flex;
    width: 100%;
    padding-top: 20px;

    .subscription-btn {
      text-transform: none;
      width: 100%;
      border: 2px solid rgba(0, 41, 255, 1);
      border-radius: 4px;
      color: rgba(0, 41, 255, 1);
      font-weight: 700;
      cursor: pointer;
    }
  }

  .mui-radio {
    margin: 9px 0px 9px 9px !important;
    padding: 0px !important;
  }
}

@media (max-width: 1023px) {
  .pricing-card {
    padding: 24px;
    min-height: fit-content;

    .title-section {
      row-gap: 6px;
      padding-bottom: 20px;

      .title {
        font-size: 27px;
      }

      .price-display {
        font-size: 27px;
      }

      .package-description {
        font-size: 18px;
      }

      .pricing-period {
        font-size: 12px;
      }
    }

    .body-section {
      .feature-text {
        font-size: 18px;
      }

      .white-circle {
        width: 28px;
        height: 28px;
      }

      .transparent-circle {
        width: 28px;
        height: 28px;
      }

      .check-icon {
        width: 28px;
        height: 28px;
      }
    }

    .lower-section {
      .subscription-btn {
        padding: 10px;
        font-size: 18px;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .pricing-card {
    padding: 12px;
    min-height: 440px;

    .title-section {
      row-gap: 6px;
      padding-bottom: 15px;

      .title {
        font-size: 24px;
      }

      .price-display {
        font-size: 24px;
      }

      .package-description {
        font-size: 15px;
      }

      .pricing-period {
        font-size: 10px;
      }
    }

    .body-section {
      .feature-text {
        font-size: 14.5px;
      }

      .white-circle {
        width: 20px;
        height: 20px;
      }

      .transparent-circle {
        width: 20px;
        height: 20px;
      }

      .check-icon {
        width: 20px;
        height: 20px;
      }
    }

    .lower-section {
      .subscription-btn {
        padding: 8px;
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 1280px) {
  .pricing-card {
    padding: 24px;
    min-height: 560px;

    .title-section {
      row-gap: 6px;
      padding-bottom: 10px;

      .title {
        font-size: 25px;
      }

      .price-display {
        font-size: 25px;
      }

      .package-description {
        font-size: 16px;
      }

      .pricing-period {
        font-size: 12px;
      }
    }

    .body-section {
      .feature-text {
        font-size: 16px;
      }

      .white-circle {
        width: 28px;
        height: 28px;
      }

      .transparent-circle {
        width: 28px;
        height: 28px;
      }

      .check-icon {
        width: 28px;
        height: 28px;
      }
    }

    .lower-section {
      .subscription-btn {
        padding: 10px;
        font-size: 16px;
      }
    }
  }
}